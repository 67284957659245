/* Resets */

* {
  box-sizing: border-box; /* Ensures padding is not included for width calculations */
  margin: 0; /* Kill default values defined by the browser */
  padding: 0; /* Kill default values defined by the browser */
  font-family: inherit;
  font-size: inherit;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, main, section {
  display: block;
}

/* No default list style for lists */
ol, ul {
  list-style: none;
}

/* No default spacing for table cells */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Kill default values for a tags */
a { 
  font-weight: normal;
  text-decoration: none;
  color: inherit;
}

/* Kill default values for buttons */
button {
  background-color: transparent;
  border: none;
}

/* Add pointer for links and buttons */
a, button {
  cursor: pointer;
}

/* General */

html, body, #root {
  min-height: 100vh;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  background-color: white;
  color: black;
}

.view_container {
  min-height: 100vh;
  background: url('../assets/images/k-background.png');
}

.content_container {
  margin: 0 auto;
  max-width: 600px;
  padding: 30px;
}

@media screen and (min-width: 600px) {
  .content_container {
    padding: 60px;
  }
}

.submit_button_container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.signup_heading {
  margin: 0 auto;
  max-width: 375px;
  font-size: 1.75rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.completion_heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* Questionnaire */

.loading_indicator {
  display: flex;
  justify-content: center;
  margin: 4rem;
}

.loading_message {
  margin: 0 auto;
  max-width: 375px;
}

.questionnaire_heading {
  margin: 0 auto;
  max-width: 375px;
  font-size: 2rem;
  margin-bottom: 3rem;
}

.question_container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.question_number_label {
  background-color: black;
  border-radius: 6px;
  color: white;
  font-size: .85rem;
  text-transform: uppercase;
  padding: .5rem;
  margin-bottom: 1.3rem;
  align-self: flex-start;
}

.form_container {
  margin: 0 auto;
  max-width: 375px;
  margin-bottom: 1rem;
}

.form_item {
  margin-bottom: 1rem;
}

/* Recommendations */

.recommendations_heading {
  font-size: 1.5rem;
  margin-bottom: 1.3rem;
}

.recommendation_card_container {
  margin-bottom: 3rem;
}

.recommendation_card_image {
  width: 100%;
  height: auto;
  margin-bottom: .6rem;
}

.recommendation_card_title {
  font-size: 2rem;
  margin-bottom: .6rem;
}

.recommendation_card_category {
  font-size: 1rem;
  color: #ff7629;
}

.recommendation_card_text {
  font-size: .9rem;
  color: #7d7d7d;
  margin-bottom: 1rem;
}

/* Rewards */

.reward_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  padding: 15px;
  overflow-y: auto;
}

.reward_container {
  background-color: white;
  padding: 15px;
}

@media screen and (min-width: 600px) {
  .reward_container {
    padding: 30px;
  }
}

.reward_business_category {
  font-size: 1rem;
  color: #ff7629;
}

.reward_business_name {
  font-size: 2rem;
  margin-bottom: .6rem;
}

.reward_business_location {
  margin-bottom: 1rem;
}

.reward_image {
  width: 100%;
  height: auto;
  margin-bottom: .75rem;
}

.reward_text {
  margin-bottom: 1rem;
}

.reward_description {
  font-size: 1.5rem;
  margin-bottom: .3rem;
}

.reward_instructions {
  font-size: 1rem;
  line-height: 1.4rem;
}
